/*
 * 업무구분: 사고보험금 조회
 * 화 면 명: MSPPM816D
 * 화면설명: 사고보험금 조회 > 접수중단건 탭
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.03
 * 작 성 자: 김진원
 */
<template>
 <ur-page-container class="msp" :show-title="false" :topButton="false" @on-scroll-bottom="fn_GetService"> 
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb100">      
        <ur-box-container v-if="totalCount > 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list type-2">
          <mo-list :list-data="bUAcdtClamdDVO">
            <template #list-item="{item}">
              <mo-list-item :class="{'on' : item.data === true}">
                <!-- <mo-checkbox v-model="item.checked"/>  -->
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.acdtInsrAplStrYmd}}</span>
                     <mo-button color="normal" shape="border" size="small" class="black" @click="fn_SignBottomSheetOpen(item)">{{item.insrdYn==='N'?'수익자서명':'피보험자서명'}}</mo-button>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="fs16rem fwb maxW100">(피) {{item.acdtrNm}}</span>
                    <span class="crTy-bk7 fs14rem ml10">{{item.acdtrBrthYmd}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="fs16rem fwb maxW100">(수) {{item.bnfrNm}}</span>
                    <span class="crTy-bk7 fs14rem ml10">{{item.bnfrBrthYmd}}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <span class="crTy-bk7 fs14rem mr10">접수창구 </span><span>{{item.acpnWndOrgNm}}</span><em>|</em>
                    <span class="crTy-bk7 fs14rem mr10">사유 </span><span>{{item.clamRsnCdNm}}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
        <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="txtGray">접수 중단건이 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- 컨텐츠영역  //-->

    <!-- 바텀시트 : 버튼영역 전자서명 알림톡 발송, 이어하기  버튼  -->
    <mo-bottom-sheet ref="buttonbottomsheet" class="ns-bottom-sheet msp-bottomSt-buttonTy1">
      <div class="content-area">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative ns-btn-ty1">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="primary" size="medium" class="bgcolor-2 bd-radius-6 blue" @click="fn_SendKaKaoIapTalk">전자서명 알림톡 발송</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="medium" class="bgcolor-2 bd-radius-6 blue" @click="fn_lastProcess">이어하기</mo-button>
            </div>
          </ur-box-container>
      </div>
    </mo-bottom-sheet>
    <!-- 바텀시트 : 버튼영역 전자서명 알림톡 발송, 이어하기  버튼  //-->
 </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import BUCommonUtil from '@/ui/bu/BUCommonUtil'
import DateUtil from '@/ui/sp/common/exDateUtils'
import moment from 'moment'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: "MSPPM816D",
  screenId: "MSPPM816D",
  components: {
  
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1} // 부모 선택한 현재 탭 인덱스
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      holyDayChkDateArry: [], // 현재날짜+10일까지 날짜정보 배열
      bUAcdtClamdDVO:[], //접수중단건 리스트
      detailInfo: {}, //리스트 row정보
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: '0'}], //paging
      step1Data: {
        insured: {}, //피보험자
        beneficiary: {}, //수익자
      },
    }
  },//data

  created () {
    this.fn_serviceCall()
  },//created

  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    slctTabIdx(){
      //console.log(this.$options.screenId + " : this.slctTabIdx = "+ this.slctTabIdx)
    },
  },//watch

  computed: {
    totalCount(){
      return this.bUAcdtClamdDVO.length
    }
  },//computed

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_serviceCall
    * 설명        : 레거시&인터페이스 서비스 콜
    ******************************************************************************/
    fn_serviceCall() {

      let addDay = 0

      if (this.holyDayChkDateArry.length === 0) {
        addDay = -10
      } else {
        addDay = -20
      }

      let params = {
        appId: 'sli.ic',
        trnstId: '',
        fnctScCd: 'S',
        serviceId: '',
        reqSrvcNm: 'ICCommMknoCtlImpl',
        reqMthdNm: 'selListYmdInfo',
        reqVONm: 'sli.ch.vo.ic.comm.ICYmdMngListVO',
        resVONm: 'sli.ch.vo.ic.comm.ICYmdMngListVO',
        srnId: this.$options.screenId, // 대표 화면 명
        data: {
          slrcYmYmd: DateUtil.addDate(DateUtil.fn_CurrentDate(), addDay)
        }
      }

      PSServiceManager.invoke(params,
                            /* 성공 callback */this.fn_ServiceResultHandler, 'S',
                            /* 실패 callback */this.fn_ServiceFaultHandler)
    },

    /******************************************************************************
    * Function명  : fn_ServiceResultHandler
    * 설명        : 서비스 호출결과 함수
    ******************************************************************************/
    fn_ServiceResultHandler(result) {
      if( result.data !== null && result.data.icymdMngVO.length > 0 ){
        
        if( this.holyDayChkDateArry.length === 0 ){
          this.holyDayChkDateArry = result.data.icymdMngVO
          this.fn_serviceCall() // 장기 연휴 처리를 위한 날짜 10일 추가 조회

        } else {
          var arrLen = result.data.icymdMngVO.length - 1

          for ( let i = arrLen; i > -1; i-- ) {
            this.holyDayChkDateArry.unshift(result.data.icymdMngVO[i]) //unshift array 맨 앞에 추가
          }
          this.fn_GetService(true)

        }
      }
    },
    fn_ServiceFaultHandler (event) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },



    /******************************************************************************
    * Function명 : fn_GetService
    * 설명       :서비스 호출
    * param      : isPageInit 는 create 에서 호출 되는 경우 true 로 셋팅 해서 호출 함.
    *              scroll down event 로 호출 되는 경우에는 Event 객체가 온다. 
    ******************************************************************************/
    fn_GetService(isPageInit) {
      console.log('fn_getService tab2...')

      if( isPageInit === true ){
        this.stndKeyList[0].stndKeyVal = '0'
      }

      // 다음 페이지 번호 (마지막 페이지는 stndKeyVal 의 값이 없다.) [{stndKeyId: 'next_key', stndKeyVal: '1'}]
      if( this.$bizUtil.isEmpty(this.stndKeyList[0].stndKeyVal) ){
        return
      }

      // 초기 조회면 ' ' (공백) 값을 넘긴다.
      const params = {
        nextKeyStrYmd: ' ',
        nextKeyEndYmd: ' ',
        nextKeyAcdtrId: ' ',
        acdtInsrAplStrYmd: this.fn_currntDayPlusDateChk(5),
        acdtInsrAplEndYmd: Number(moment(new Date().toISOString()).format('YYYYMMDD')),
        mini: 'Y',
        readCount: 20
      }

      var lv_Vm = this
      this.post(lv_Vm, params, 'txTSSBU28D1', 'S')
        .then(function (response) {

          let responseList = null
          
          if( response.body && response.body.bUAcdtClamdDVO ){
            responseList = response.body.bUAcdtClamdDVO

            //paging list reset
            if( isPageInit ){
              lv_Vm.bUAcdtClamdDVO.splice(0)
            }
            
            //add list
            lv_Vm.bUAcdtClamdDVO = lv_Vm.bUAcdtClamdDVO.concat(responseList)

            //paging setting
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList // 더보기 키값
          }

          lv_Vm.$emit('tab2Total', lv_Vm.totalCount)

          // NextKey 방식의 경우, 서버로부터 nextKey를 받아옴
          //lv_Vm.lv_nextKeyStrYmd = response.body.nextKeyStrYmd
          //lv_Vm.lv_nextKeyEndYmd = response.body.nextKeyEndYmd
          //lv_Vm.lv_nextKeyAcdtrId = response.body.nextKeyAcdtrId

          // 받은 데이터가 pageSize 보다 작으면, 더이상 조회할 데이터가 없음을 의미
          //if (response.body.bUAcdtClamdDVO.length < lv_Vm.lv_Params.readCount) {
          //  lv_Vm.lv_MoreData = false
          //}
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /************************************************************************************************
     * Function명  : fn_currntDayPlusDateChk
     * 설명        : 현재날짜-10일까지 날짜정보 처리 -> 공휴일을 제외한 워킹데이 처리할때 사용하면 됨
     ************************************************************************************************/
    fn_currntDayPlusDateChk (cnt) {
      let dayCnt = 0
      let holyDayChkDate = ''
      let i

      for (i = this.holyDayChkDateArry.length - 1; i > 0; i-- ) {
        if ( this.holyDayChkDateArry[i].holyCd === '00') {
          dayCnt++
        }
        if ( dayCnt === cnt ) {
          holyDayChkDate = this.holyDayChkDateArry[i].slrcYmYmd
          break
        }
      }

      return holyDayChkDate
    },


    /************************************************************************************************
     * Function명  : open
     * 설명        : 서명버튼클릭
     ************************************************************************************************/
    fn_SignBottomSheetOpen(item){
      this.detailInfo = item
      this.$refs.buttonbottomsheet.open()
    },

    /**
     * @description 이어하기 진행.
     */
    fn_lastProcess(){
      this.$refs.buttonbottomsheet.close()
      this.fn_SelCustInfo(1)
    },


    /**
     * @description 알림톡 발송
     */
    fn_SendKaKaoIapTalk(){
      let receiverName = this.detailInfo.insrdYn === 'N' ? this.detailInfo.bnfrNm : this.detailInfo.acdtrNm

      if( this.$bizUtil.isEmpty(this.detailInfo.contPtcpTelnoEncr) ){
        lv_Vm.getStore('confirm').dispatch('ADD', `${receiverName}고객님의 연락처가 없어서 알림톡 발송을 할 수 없습니다.`)
        return
      }

      let params = {
        insrdYn: this.detailInfo.insrdYn,
        acdtrNm: this.detailInfo.acdtrNmEncr,
        bnfrNm: this.detailInfo.bnfrNmEncr,
        targetUrl: this.detailInfo.targetUrl,
        contPtcpTelnoEncr: this.detailInfo.contPtcpTelnoEncr,
      }

      console.log('send message : ', params)

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSBU31P2', 'S')
        .then(function (response) {
          lv_Vm.$refs.buttonbottomsheet.close()

          // 서버 데이터 가져오기
          if (response.body !== null) {
            console.log('이어하기 고객 정보 조회 결과 : ', response.body)
            let resultMsg = ''
            if( response.body.isSendSuccess ){
              resultMsg = '알림톡을 발송 하였습니다.'
            } else {
              if( response.msgComm.msgCd === 'EFWS003' ){
                resultMsg = '알림톡이 발송 되지 않았습니다.'
              } else {
                resultMsg = response.msgComm.msgCd
              }
            }

            lv_Vm.getStore('confirm').dispatch('ADD', resultMsg)

          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },


    /**
     * @description 고객정보조회
     */
    fn_SelCustInfo(type){
      let params = {
        custId: type === 1 ? this.detailInfo.acdtrId : this.detailInfo.bnfrId
      }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSBU02D1', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            console.log('이어하기 고객 정보 조회 결과 : ', response.body)

            if (response.body.custId.trim() === '') {
              // 이름과 식별번호가 일치하지 않는 케이스 처리
              lv_Vm.getStore('confirm').dispatch('ADD', '이름과 식별번호가 일치하지 않습니다.\n다시 확인해 주세요.')

            } else {
              if (type === 1) {
                lv_Vm.step1Data.insured = response.body
                lv_Vm.step1Data.insured.sex = lv_Vm.$bizUtil.getGenderFromKnb(response.body.rrn)
                lv_Vm.step1Data.insured.age = lv_Vm.$bizUtil.getAgeFromKnb(response.body.rrn)
                lv_Vm.step1Data.insured.custType = '피보험자'
                // lv_Vm.step1Data.insured.knbFrno = response.body.rrn.substr(0, 6)
                lv_Vm.fn_SelCustInfo(2)

              } else {
                lv_Vm.step1Data.beneficiary = response.body
                lv_Vm.step1Data.beneficiary.sex = lv_Vm.$bizUtil.getGenderFromKnb(response.body.rrn)
                lv_Vm.step1Data.beneficiary.age = lv_Vm.$bizUtil.getAgeFromKnb(response.body.rrn)
                lv_Vm.step1Data.beneficiary.custType = '수익자'
                //lv_Vm.step1Data.beneficiary.knbFrno = response.body.rrn.substr(0, 6)
                lv_Vm.fn_Blocking()
              }
            }
          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생했습니다.')
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },


    fn_Blocking(){
      var params = {
        inqrScCd: '10',
        clamrId: this.step1Data.beneficiary.custId, // 청구자(수익자)
        acdtrNm: this.step1Data.insured.custNm,
        knbFrno: this.step1Data.insured.knbFrno, // 주민번호 앞번호
        knbBkno: this.step1Data.insured.knbBkno, // 주민번호 뒷번호 평문
        knbBknoCipher: this.step1Data.insured.knbBknoCipher, // 주민번호 뒷번호
        rrnCipher: this.step1Data.insured.rrnCipher,
        fstHpgAcpnDtm: '',
      }

      let resultData = {
        CustomerType: 'OTHER'
      }

      let lv_Vm = this

      BUCommonUtil.fn_BlockingService(this, params, resultData, function(data) {
        let returnValue = data.returnValue
        if( returnValue !== '' ){
          lv_Vm.getStore('confirm').dispatch('ADD', returnValue)

        } else {
          lv_Vm.$router.push({
            name: 'MSPBU001M',
            params: {
              preInfo: lv_Vm.detailInfo,
              step1Data: lv_Vm.step1Data,
              //custType: lv_Vm.detailInfo.insrdYn === 'Y' ? 'A' : 'B',
              //datas: lv_Vm.datas
            }
          })
          
        }
      })
    },
 },// methods

}//export default


</script>