/*
 * 업무구분: 사고보험금 조회
 * 화 면 명: MSPPM911D
 * 화면설명: 사고보험금 조회 > 접수현황 탭
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.03
 * 작 성 자: 김진원
 */
<template>
 <ur-page-container class="msp" :show-title="false" :topButton="false" @on-scroll-bottom="fn_GetService(false)">
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">
        <ur-box-container v-if="totalCount > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list ns-accodian-mode">
          <mo-list :list-data="bUInsmPymGudnObjListSVO">
            <template #list-item="{item, index}">
              <mo-list-item expandable :ref="'expItem_' + index" :preventExpand="true" @on-expand-click="(param) =>{(fn_OnClickArrowDown(index, item, param))}">
                <div class="list-item__contents pr0">
                  <div class="list-item__contents__title fexTy3">
                    <span class="fex-rw wauto align-item-center">
                      <span class="name maxW100">{{item.acdtrNm}}</span>
                      <span class="txtGray fs16rem fwn">({{item.acdtrRrnMask}})</span>
                    </span>                    
                    <span>
                      <mo-badge v-if="item.isShowListAcpn" class="badge-sample-badge sm blue" text="심사상태" shape="status">{{item.jdgStatCdNm}}</mo-badge>
                      <mo-badge v-else class="badge-sample-badge sm blue" text="심사상태" shape="status">{{item.jdgStatCdNm}}</mo-badge>
                    </span>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span class="txtGray">{{item.acdtInsrAcpnObjTypCdNm}}</span><em>|</em>
                      <span class="txtGray"><span class="fs14rem crTy-bk7 pr10">접수일</span> {{item.acpnYmd}} </span>
                    </div>
                    <div class="right_box"></div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="info_box ic-arrow flex-auto1">
                      <span class="txtGray pr10">실지급액</span>
                      <span class="fwb crTy-blue2">{{item.clamAmt}}</span>
                    </div>
                    <div class="right_box" v-if="item.buttonName !== '-'">
                      <mo-button color="normal" shape="border" size="small" class="blue ml10 bgcolor-2 fwm" @click="fn_ClickButtonName(item)">{{item.buttonName}}</mo-button>
                    </div>
                    <div class="right_box" v-else-if="item.buttonTypeMsg !== '' && !(item.jdgStatCdNm === '접수완료' || item.jdgStatCdNm === '서류접수취소')">
                      <mo-button color="normal" shape="border" size="small" class="blue ml10 bgcolor-2 fwm" disabled>원본서류필요</mo-button>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="list-item-detail" style="">
                      <ul>
                        <li class="mt0" v-if="isNotEmpty(item.bnfrNm)">
                          <div class="title-cell w105px line-h-Ty1">
                            <strong class="fwn crTy-bk1">수익자</strong>
                          </div> 
                          <div class="value-cell">
                            <span class="crTy-bk1">{{item.bnfrNm}}</span>
                          </div>
                        </li>
                        <li class="mt0">
                          <div class="title-cell w105px line-h-Ty1">
                            <strong class="fwn crTy-bk1">접수창구</strong>
                          </div> 
                          <div class="value-cell">
                            <span class="crTy-bk1">{{item.acpnFofNm}}</span>
                          </div>
                        </li> 
                        <li class="mt0">
                          <div class="title-cell w105px line-h-Ty1">
                            <strong class="fwn crTy-bk1">사유</strong>
                          </div> 
                          <div class="value-cell">
                            <span class="crTy-bk1">{{item.clamRsnCdNm}}</span>
                          </div>
                        </li> 
                        <li class="mt0 fexTy5">
                          <div class="cell-group">
                            <span class="crTy-bk1 ml0">동시접수<b class="fwb pl5" :class="item.stmusClamYn === 'Y' ? 'crTy-blue2' : 'crTy-orange2'"> {{item.stmusClamYn}}</b> </span><em class="mt0">|</em>
                            <span class="crTy-bk1 ml0">반송<b class="fwb pl5" :class="item.snbkYn01Val === 'Y' ? 'crTy-blue2' : 'crTy-orange2'">{{item.snbkYn01Val}}</b></span><em class="mt0">|</em>
                            <span class="crTy-bk1 ml0">미결<b class="pl5" :class="item.acdtPendStatCd01Nm === 'Y' ? 'crTy-blue2' : 'crTy-orange2'">{{item.acdtPendStatCd01Nm}}</b></span>
                          </div>
                        </li>
                        <li class="mt0" v-if="isNotEmpty(item.foreignCurr)">
                          <div class="title-cell w105px line-h-Ty1">
                            <strong class="fwn crTy-bk1">외화지급</strong>
                          </div> 
                          <div class="value-cell">
                            <span class="crTy-bk1">{{item.foreignCurr}}</span>
                          </div>
                        </li>                         
                      </ul>
                    </div>
                    <!-- 심사진행현황 추가 240126 -->
                    <div class="ing-content-box" v-if="item.isShowListAcpn">
                      <strong class="title">심사진행현황</strong>
                      <div class="tool-top-box">
                        <span class="name">사고자<b>{{lv_BUInsmClamPrgStuRsListVO.acdtrNm || item.acdtrNm}}</b></span>
                        <span class="ns-tool-tip">                          
                          <mo-icon :id="'status_tooltip_' + index" icon="msp-tool-tip"></mo-icon>
                          <mo-tooltip :target-id="'status_tooltip_' + index" class="tool-tip-box ns-tool-tip-scroll">
                            <span>
                              <b>서류접수일</b>
                              <br>
                              -보험금 청구서류를 접수한 날짜
                              <br><br>
                              <b>서류심사</b>
                              <br>
                              -처리 담당자가 서류를 확인하고, 지급, 본사심사, 현장 확인 여부 결정하는 단계
                              <br><br>
                              <b>현지실사</b>
                              <br>
                              -심사전문회사인 삼성생명서비스(주)에서 의료기관이나 관련기관 등 보험금과 관련한 사항 현장 확인하는 단계
                              <br><br>
                              <b>본사심사</b>
                              -서류심사 또는 현장확인 완료 후 최종지급여부를 결정하는 단계
                              <br><br>
                              <b>심사완료</b>
                              -보험금심사가 완료되어 지급/부지급 예정인 상태
                              <br><br>
                              <b>종료</b>
                              -사고보험금 청구에 대한 처리가 완료된 상태
                              <br><br>
                            </span>
                            <div class="bottom-box">
                              <span>
                                &#8251; 고객명은 보험금 청구대상 피보험자(보험대상자)의 성명입니다.
                              </span>
                              <span>
                                &#8251; 조회일 기준 3개월 이내 컨설턴트가 접수한 사고보험금 청구건에 한해 조회가 가능합니다.
                              </span>
                            </div>
                          </mo-tooltip>
                        </span>
                      </div>                      
                      <div class="ing-content">
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(0)">
                          <span class="list-step">서류접수 {{lv_BUInsmClamPrgStuRsListVO.docuAcpnYmd}}</span>
                        </div>
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(1)">
                          <span class="list-step">서류심사 {{lv_BUInsmClamPrgStuRsListVO.docuJdgPrgStatCdNm}}</span>
                        </div>
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(2)">
                          <span class="list-step">현지실사 {{lv_BUInsmClamPrgStuRsListVO.lossAssmtPrgStatCdNm}}</span>
                        </div>
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(3)">
                          <span class="list-step">본사심사 {{lv_BUInsmClamPrgStuRsListVO.hdofJdgPrgStatCdNm}}</span>
                        </div>
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(4)">
                          <span class="list-step">심사완료 {{lv_BUInsmClamPrgStuRsListVO.pymAprvPrgStatCdNm}}</span>
                        </div>
                        <div class="ing-item" :class="fn_DcuAcpnIsActiClass(5)">
                          <span class="list-step">종료 {{lv_BUInsmClamPrgStuRsListVO.endYmd}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- 심사진행현황 추가 240126 -->
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="txtGray">데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- 컨텐츠영역  //-->

    <!-- 바텀시트 : 사고자/수익자  -->
    <mo-bottom-sheet ref="nsbottomsheet"  class="ns-bottom-sheet msp-bottomSt-alert">
      <div class="customer-info-txt txt-center">
        <p class="ment">사고자/수익자 선택해주세요</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white">사고자</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue">수익자</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 바텀시트 : 사고자/수익자  -->
 </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import moment from 'moment'
import MSPPM912M from '@/ui/pm/MSPPM912M.vue' //심사진행현황
import MSPPM913M from '@/ui/pm/MSPPM913M.vue' //지급내역
import MSPPM812P from '@/ui/pm/MSPPM812P.vue' //휴대폰/카카오 인증
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: "MSPPM911D",
  screenId: "MSPPM911D",
  components: {
    MSPPM812P,
  },

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    slctTabIdx : {type:Number, default:1}, // 부모 선택한 현재 탭 인덱스
    tabData: {type:Object} //탭정보
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      bUInsmPymGudnObjListSVO: [], //리스트
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: '0'}], //paging
      pageRowCnt: '25',
      datas:{
        menuType: '', //01: 본인신청, 02: 피수상이, 03: 자녀신청
        custInfoContr: {}, // 청구자/수익자(계약자) 고객정보
        custInfoInsrd: {}, // 사고자(피보험자) 고객정보
      },
      lv_BUInsmClamPrgStuRsListVO: {}, // 심사진행현황 List VO
      lv_AlertPop: '' // Alert 팝업 객체
    }
  },//data

  created () {
    console.log('tab1 page init')

    this.fn_GetService(true)
  },//created

  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch
  
  computed: {
    totalCount(){
      return this.bUInsmPymGudnObjListSVO.length
    }
  },//computed

  /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /**
     * @name isNotEmpty
     * @description 개발 편의
     * @param {Object|String} value 체크할 값
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },
    
    /**
     * @name fn_GetService
     * @description 신청보유계약조회 호출
     * @param {Boolean} isPageInit '사용자호출 : true', 'on-scroll-bottom 호출 : Event'
     */
    fn_GetService(isPageInit) {
      console.log('fn_GetService page : ', this.stndKeyList[0].stndKeyVal)

      if( isPageInit === true ){
        this.stndKeyList[0].stndKeyVal = '0'
      }

      // 다음 페이지 번호 (마지막 페이지는 stndKeyVal 의 값이 없다.) [{stndKeyId: 'next_key', stndKeyVal: '1'}]
      if( this.$bizUtil.isEmpty(this.stndKeyList[0].stndKeyVal) ){
        return
      }

      const beginDate = this.$props.tabData.beginDate
      const endDate = this.$props.tabData.endDate

      //const beginDate = '20230101'
      //const endDate = '20230131'

      console.log('beginDate : ', beginDate, 'endDate : ', endDate)

      if( this.$bizUtil.isEmpty(beginDate) ) {
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', this.$t('bu')['EBUC006']) // 조회시작일을 입력하세요
        return
      }

      if ( this.$bizUtil.isEmpty(endDate) ) {
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', this.$t('bu')['EBUC007']) // 조회종료일을 입력하세요.
        return
      }

      if ( beginDate.substr(0, 6) !== endDate.substr(0, 6) ) {
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', this.$t('bu')['EBUC009']) // 조회기간은 같은 월이어야합니다.
        return
      }

      if ( beginDate > endDate ) {
        this.getStore('confirm').dispatch('SETCOLOR', 'purple')
        this.getStore('confirm').dispatch('ADD', this.$t('bu')['EBUC008'] + '(' + beginDate + '->' + endDate + ')')
        this.$emit('setDate', beginDate, endDate)
        return
      }

      let params = {
        befAcpnYmd: this.$bizUtil.replaceAll(beginDate.substr(0, 10), '-', ''), // 조회시작일자
        aftAcpnYmd: this.$bizUtil.replaceAll(endDate.substr(0, 10), '-', '') // 조회종료일자
      }

      let lv_Vm = this

      this.post(this, params, 'txTSSBU27S1', 'S')
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseList = response.body.bUInsmPymGudnObjListSVO
            
            if (responseList !== null) {

              responseList.forEach((item) => {
                
                item.foreignCurr = item.curr === 'USD' ? 'Y' : '' //외회지급
                item.objType = item.acdtInsrAcpnObjTypCdNm === '본인계약' ? 'lightblue' : 'lightgreen' //신청대상(본인, 자녀)
                item.isShowListAcpn = ['태블릿가접수', '서류접수', '서류보완요청', '접수완료', '서류접수취소'].indexOf(item.jdgStatCdNm) === -1 //심사진행현황 팝업호출
                
                // 서류추가 및 지급내역 버튼
                if( item.buttonType === true && (item.jdgStatCdNm === '태블릿가접수' || item.jdgStatCdNm === '서류접수' || item.jdgStatCdNm === '서류보완요청') ){
                  item.buttonName = '서류추가'

                } else if( item.buttonType === false && (item.jdgStatCdNm === '태블릿가접수' || item.jdgStatCdNm === '서류접수' || item.jdgStatCdNm === '서류보완요청' || item.jdgStatCdNm === '접수완료' || item.jdgStatCdNm === '서류접수취소') ){
                  item.buttonName = '-'

                } else if( item.isShowListAcpn ) {
                  item.buttonName = '지급내역'

                }
              })

              //paging list reset
              if( isPageInit ){
                lv_Vm.bUInsmPymGudnObjListSVO.splice(0)
              }

              //add list
              lv_Vm.bUInsmPymGudnObjListSVO = lv_Vm.bUInsmPymGudnObjListSVO.concat(responseList)

              //paging setting
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList // 더보기 키값

            }
          } else if (response.msgComm.msgCd === 'EEAC928') {
            lv_Vm.bUInsmPymGudnObjListSVO.splice(0)

          } else {
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

          }

          lv_Vm.$emit('tab1Total', lv_Vm.totalCount)

        }).catch(function (error) {
          window.vue.error(error)
        })
    },



    /**
     * @name getCustInfoServiece
     * @description 사고자청구자수익자고객정보조회 호출
     * @param {Object} item 접수현황 리스트 클릭 건
     */
    getCustInfoServiece(item) {
      let params = {
        bUInsmPymGudnObjListSVO: [item]
      }

      let lv_Vm = this
      this.post(this, params, 'txTSSBU27S2', 'S')
        .then(function (response) {
          
          // 서버 데이터 가져오기
          if (response.body !== null) {

            // 청구자고객정보/수익자고객정보
            if (response.body.clamrIdCustInfoSVO === null) {

              if (item.acdtInsrAcpnObjTypCd === '02') { // 수익자상이
                lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC003']) // 수익자 고객 조회 정보가 없습니다.
              } else {
                lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC001']) // 청구자 고객 조회 정보가 없습니다.
              }
              return

            }

            // 사고자고객정보
            if (response.body.acdtrIdCustInfoSVO === null) {
              lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC002']) // 사고자 고객 조회 정보가 없습니다.
              return

            }

            lv_Vm.datas.custInfoContr = response.body.clamrIdCustInfoSVO // 청구자/수익자고객정보
            lv_Vm.datas.custInfoInsrd = response.body.acdtrIdCustInfoSVO // 사고자고객정보

            if (lv_Vm.$bizUtil.isEmpty(item.acdtInsrAcpnObjTypCd)) {

              if (item.clamrId === item.acdtrId) {
                lv_Vm.datas.menuType = '01' // 사고자와 청구자가 같다면 본인 신청
              } else {
                lv_Vm.datas.menuType = '03' // 사고자와 청구자가 다르면 자녀 신청
              }
              
            } else {
              lv_Vm.datas.menuType = item.acdtInsrAcpnObjTypCd

            }

            if( response.body.authPopupYn === 'Y' ){ // 본인인증오픈팝업여부

              if( lv_Vm.datas.menuType !== '02' ){
                lv_Vm.fn_MoveMSPPM811M('', item) // 본인인증 팝업
              } else {
                lv_Vm.fn_ConfirmOpen(item) // 사고자/수익자 선택 팝업 호출
              }

            }

          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC004']) // 고객 조회 정보가 없습니다.
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },



    /**
     * @name fn_ClickButtonName
     * @description 사고보험금 진행결과(서류추가 또는 지급내역 버튼) 버튼 클릭
     * @param {Object} item 사고자 데이터 객체
     */
    fn_ClickButtonName(item){

      //asis : docAdd
      if( item.buttonName == '서류추가' ){
        if ( item.buttonTypeMsg !== '') {
          this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', item.buttonTypeMsg)
          return
        }

        if( item.trtYn !== 'N' ){
          return
        }

        this.getCustInfoServiece(item) // 본인 인증 하기전 계약건의 고객ID로 사고자와 청구자 수익자 개인정보 호출

      //asis : pymBrkdn
      } else if( item.buttonName == '지급내역' ){
        this.fn_OpenMSPPM913M(item) //지급내역 팝업 활성화

      }

      //asis : listAcpn
      else {
        if( item.buttonTypeMsg !== '' && !(item.jdgStatCdNm === '접수완료' || item.jdgStatCdNm === '서류접수취소') ){
          this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', item.buttonTypeMsg)
        }

      }
    },


    /**
     * @name fn_OpenMSPPM912M
     * @description 심사진행현황 팝업
     * @param {Object} pItem 사고자 데이터 객체
     */
    fn_OpenMSPPM912M(pItem) {
      if( !pItem.isShowListAcpn ){
        return
      }
      const acpnYmd = moment(pItem.acpnYmd).format('YYYYMMDD')

      this.popupPM912M = this.$bottomModal.open(MSPPM912M, {
        properties: {
          item: {
            acdtrNm: pItem.acdtrNm,
            aftAcpnYmd: acpnYmd,
            befAcpnYmd: acpnYmd,
            acdtrRrn: pItem.acdtrRrn
          }
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popupPM912M)
          }
        }
      })
    },


    /**
     * @name fn_OpenMSPPM913M
     * @description 지급내역 팝업
     * @param {Object} pItem 사고자 데이터 객체
     */
    fn_OpenMSPPM913M(pItem) {
      this.popupPM913M = this.$bottomModal.open(MSPPM913M, {
        properties: {
          item: {
            acdtrId: pItem.acdtrId,
            acpnYmd: moment(pItem.acpnYmd).format('YYYYMMDD'),
            acdtrNm: pItem.acdtrNm,
            acdtrNmMask: pItem.acdtrNmMask,
            acdtrRrn: pItem.acdtrRrn
          }
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popupPM913M)
          }
        }
      })
    },

    /**
     * @name fn_MoveMSPPM811M
     * @description 구비서류 추가 진행 화면으로 이동
     * @param {String} custType 수익자상이(피수상이) 인 경우에만 값이 들어 옴.
     * @param {Object} item 선택 된 리스트 아이템
     */
    fn_MoveMSPPM811M(custType, item){
      this.$router.push({name: 'MSPPM811M', params: {custType: custType, datas: this.datas, selectedItem: item}})
    },


    /**
     * @name fn_ConfirmOpen
     * @description 사고자/수익자 선택
     */
    fn_ConfirmOpen(item) {
      let contentMsg = '사고자/수익자 선택해주십시오'
      
      let lv_Vm = this
      let alertPopup = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          //single: true,
          title: "사고자/수익자 선택",
          content: contentMsg,
          title_pos_btn: "수익자",
          title_neg_btn: "사고자"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertPopup)
            lv_Vm.fn_MoveMSPPM811M('B', item) //수익자
          },
          onPopupClose: () => {
            this.$bottomModal.close(alertPopup)
            lv_Vm.fn_MoveMSPPM811M('A', item) //사고자
          }
        }
      })
    },

     /**
     * Function명 : fn_GetBUInsmClamPrgStuRs
     * 설명       : 심사진행현황 데이터 조회 및 화면에 반영
     * @return {none} - 반환없음
     */
    fn_GetBUInsmClamPrgStuRs (index, pItem) {      
      this.lv_BUInsmClamPrgStuRsListVO = []
      if( !pItem.isShowListAcpn ){
        this.$refs['expItem_' + index].expand()
        return
      }
      const acpnYmd = moment(pItem.acpnYmd).format('YYYYMMDD')
      let pParams = {
        acdtrNm: pItem.acdtrNm, // 사고자명
        aftAcpnYmd: acpnYmd, // 전 접수일자
        befAcpnYmd: acpnYmd, // 후 접수일자
        acdtrRrn: pItem.acdtrRrn // 주민등록번호
      }
      let trnstId = 'txTSSBU29S1'

      this.post(this, pParams, trnstId, 'S')
        .then(response => {
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let list = response.body.bUInsmClamPrgStuRsListSVO
            if( list && list.length > 0 ){
              this.lv_BUInsmClamPrgStuRsListVO = list[0]              
            }
          } else if (response.msgComm.msgCd === 'EEAC928') { // 데이터 미존재
          } else {
            this.fn_BottomAlert(response.msgComm.msgDesc)
          }

          console.log('expItem_index', 'expItem_' + index)

          this.$refs['expItem_' + index].expand()
        })
        .catch(window.vue.error)
    },

    /**
     * Function명 : fn_DcuAcpnIsActiClass
     * 설명       : 심사진행항목과 현재 상태에 따른 적절한 클래스 제공
     * @param {Number} param - 심사진행항목 ID
     */
    fn_DcuAcpnIsActiClass (param) {
      let retCls
      let keyList = [
        'docuAcpn',
        'docuJdgPrgStat',
        'lossAssmtPrgStat',
        'hdofJdgPrgStat',
        'pymAprvPrgStat',
        'endYmd'
      ]
      let keyIsActi = keyList[param] + 'IsActi'
      let keyIsSkip = keyList[param] + 'IsSkip'

      if (this.lv_BUInsmClamPrgStuRsListVO[keyIsActi] === true) {
        retCls = 'checked'
      } else if (this.lv_BUInsmClamPrgStuRsListVO[keyIsSkip]) {
        retCls = 'skip'
      } else {
        retCls = ''
      }
      return retCls
    },

    /******************************************************************************
    * Function명 : fn_OnClickArrowDown
    * 설명       : 리스트 클릭시 실행하는 프로세스
    ******************************************************************************/
    fn_OnClickArrowDown (index, item, eventParam) {
      console.log('fn_OnClickArrowDown >')
      console.log('item : ', item)
      console.log('eventParam : ', eventParam)      
      if (eventParam) {
        this.fn_GetBUInsmClamPrgStuRs(index, item)
      } else {
        this.$refs['expItem_' + index].collapse()
      }
      // let selDate = this.fn_GetSelDate()
      // let start = selDate && selDate.startDate ? selDate.startDate : ''
      // let end = selDate && selDate.endDate ? selDate.endDate : ''
      // if (this.lv_MSPAP307Data[pIdx].child.length === 0) {
      //   if (pIdx === 0 || pIdx === 1 ) {
      //    this.fn_SelListTelCharCntnHstr(pIdx, start, end, 0, item)
      //   } else {
      //     let tIdx = pIdx - 1
      //    this.fn_SelListCmpltSchdlDtl(tIdx, start, end, 0, item)
      //   }
      // } else {
      //   if (eventParam) {
      //     this.$refs['expItem_' + pIdx].expand()
      //   } else {
      //     this.$refs['expItem_' + pIdx].collapse()
      //   }
      // }
    },

    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
 },// methods

}//export default


</script>