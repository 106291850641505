/*
 * 업무구분   : 계약관리
 * 화면 명    : MSPPM913M
 * 화면 설명  : 지급내역
 */
 <template>
  <ur-page-container title="지급내역" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-checking-box">
          <div class="tool-top-box ns-style2">
            <span class="name">사고자<b>{{item.acdtrNm}}</b></span>
          </div>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-sum" >
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum" v-if="lv_bUInsmPymGudnObjDtlVOList.length > 0">총 {{lv_bUInsmPymGudnObjDtlVOList.length}}건</span>
                <span class="sum" v-else>총 0건</span>
                <span class="ns-tool-tip">
                  <mo-icon id="location_PM913M" icon="msp-tool-tip"></mo-icon>
                  <mo-tooltip target-id="location_PM913M" class="tool-tip-box">
                    &#8251; 보험금 지급내역은 사고접수 FC의 수금 계약건만 확인가능합니다.
                    <br><br>
                    &#8251; 지급이 미완료된 건의 경우는 지급금액이 미확정된 상태이므로 안내시 유의바랍니다.
                  </mo-tooltip>
                </span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <ur-box-container v-if="lv_IsLoadingStatus === true || lv_bUInsmPymGudnObjDtlVOList.length > 0" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list ns-accodian-mode">
          <mo-list :list-data="lv_bUInsmPymGudnObjDtlVOList">
            <template #list-item="{item, idx}">
              <mo-list-item expandable :ref="'expItem_' + idx">
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{item.prdtNm}}</span>
                  </div>
                  <div class="list-item__contents__info inline">
                    <div class="info_box">
                      <span class="txtBlack txtSkip">{{item.contNo}}</span>
                    </div>
                    <div class="right_box">
                      <span class="txtBlue" v-if="item.curr==='USD'"><strong>{{$bizUtil.numberWithCommasCurr($bizUtil.replaceAll(item.totPymAmt02, ',', ''), item.curr, 'Y', 'Y', 4)}}</strong></span>
                      <span class="txtBlue" v-else><strong>{{item.totPymAmt}}</strong></span>
                    </div>
                  </div>
                  <div class="list-item__contents__info prov">
                    <div class="info_box">
                      <span class="txtBlack"><span>수익자&ensp;</span><span class="txtBlack name txtSkip">{{item.bnfrNm}}</span></span>
                    </div>
                    <div class="right_box">
                      <span class="txtBlue">{{item.acdtrScCdNm}}</span>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="list-item-detail">
                      <ul>
                        <li v-for="(title, idx2) in ['사유', '지급일자', '사유발생일', '구분']" :key="idx2">
                          <div class="title-cell">
                            <strong>{{title}}</strong>
                          </div> 
                          <div class="value-cell">
                            <span>{{item[TITLE_NAMES[title]]}}</span>
                          </div>
                        </li> 
                      </ul>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역: start  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 존재하지 않습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역: end  -->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  name: 'MSPPM913M',
  screenId: 'MSPPM913M',
  /***********************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************/
  created () {
    this.fn_GetBUInsmPymGudnObjDtl()
  },
  mounted () {
    // 화면접속로그등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면 전역 변수 선언 영역
   ***********************************************************************************/
  data () {
    return {
      lv_bUInsmPymGudnObjDtlVOList: [], // 인피니트 바인딩 리스트VO
      TITLE_NAMES: {
        사유: 'clamRsnCdNm',
        지급일자: 'pymYmd',
        사유발생일: 'rsnOccuYmd',
        구분: 'trtScCdNm'
      },
      lv_AlertPop: '',
      lv_IsLoadingStatus: true
    }
  },
  props: {
    item: {
      acdtrId: String,
      acpnYmd: String,
      acdtrNm: String,
      acdtrNmMask: String,
      acdtrRrn: String
    }
  },
  components: {
    MSPBottomAlert
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  methods: {
    /**
     * Function명 : fn_OnClose
     * 설명       : 팝업창 닫기
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_OnClose (e) {
      if (this.$popupManager) {
        this.$popupManager.close(this)
      }
    },
    /**
     * Function명 : fn_GetBUInsmClamPrgStuRs
     * 설명       : 지급내역 데이터 조회 및 화면에 반영
     * @return {none} - 반환없음
     */
    fn_GetBUInsmPymGudnObjDtl () {
      let pParams = {
        acdtrId: this.item.acdtrId,
        acpnYmd: this.item.acpnYmd,
        acdtrNm: this.item.acdtrNm,
        acdtrRrn: this.item.acdtrRrn
      }
      let trnstId = 'txTSSBU24S1'
      let auth = 'S'

      this.post(this, pParams, trnstId, auth)
        .then(response => {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.bUInsmPymGudnObjDtlListSVO
            if (responseList !== null) {
              responseList.forEach((item) => {
                item.expanded = false
                this.lv_bUInsmPymGudnObjDtlVOList.push(item)
              })
            }
          } else if (response.msgComm.msgCd === 'EEAC928') {
          } else {
            this.fn_BottomAlert(response.msgComm.msgDesc)
          }
        })
        .catch(window.vue.error)
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>
