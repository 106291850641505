/*
 * 업무구분   : 계약관리
 * 화면 명    : MSPPM911P
 * 화면 설명  : 사고보험금 상세조회조건
 */
<template>
  <ur-page-container title="상세조회" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-sch-box">
          <span class="right-txt">*대상기간(한달기준)</span>
          <div class="ns-date-picker multi" :key="lv_RenderingFlag">
            <mo-date-picker :max-date="lv_DefaultEndDate" v-model="lv_BeginDate" placeholder="발생 시작일" @input="fn_SelectDateFrom"/><span>~</span>
            <mo-date-picker :max-date="lv_DefaultEndDate" v-model="lv_EndDate"   placeholder="발생 종료일" @input="fn_SelectDateTo"/>
          </div>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetDates">초기화</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPPM911P',
  screenId: 'MSPPM911P',
  components: {
  },
  props: {
    pBeginDate: String, // 발생 시작일
    pEndDate: String, // 발생 종료일
    pDefaultBeginDate: String, // 발생 시작일 Default
    pDefaultEndDate: String // 발생 종료일 Default
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_BeginDate = this.$props.pBeginDate // 발생 시작일
    this.lv_EndDate = this.$props.pEndDate // 발생 종료일
    this.lv_DefaultBeginDate = this.$props.pDefaultBeginDate // 발생 시작일 Default
    this.lv_DefaultEndDate = this.$props.pDefaultEndDate // 발생 종료일 Default
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      lv_BeginDate: '', // 발생 시작일
      lv_EndDate: '', // 발생 종료일
      lv_DefaultBeginDate: '', // 발생 시작일 Default
      lv_DefaultEndDate: '', // 발생 종료일 Default
      lv_RenderingFlag: false,
      lv_AlertPop: '' // bottom alert component
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /**
     * Function명 : fn_SelectDateForm
     * 설명       : 시작일자를 선택했을 때 호출되는 함수
     *              시작일자가 종료일자보다 큰 경우
     *              - 시작일자를 종료일자로 변경
     * @param {Date} v: 선택한 지역 날짜 (시간 00:00:00으로 고정)
     * @return {none} - 반환없음
     */
    fn_SelectDateFrom (v) { // 시작일자를 선택했는 지 여부
      let beginDate = new Date(this.lv_BeginDate)
      let endDate = new Date(this.lv_EndDate)
      if ( endDate < beginDate ) {
        this.fn_BottomAlert(this.$t('pm')['EPMC051'] + `<BR>(${this.lv_BeginDate} → ${this.lv_EndDate})`)
        this.lv_BeginDate = this.lv_EndDate
        this.lv_RenderingFlag = !this.lv_RenderingFlag
      }
    },
    /**
     * Function명 : fn_SelectDateTo
     * 설명       : 종료일자를 선택했을 때 호출되는 함수
     *              종료일자가 종료일자보다 큰 경우
     *              - 종료일자를 시작일자로 변경
     * @param {Date} v: 선택한 지역 날짜 (시간 00:00:00으로 고정)
     * @return {none} - 반환없음
     */
    fn_SelectDateTo (v) { // 종료일자를 선택했는 지 여부
      let beginDate = new Date(this.lv_BeginDate)
      let endDate = new Date(this.lv_EndDate)
      let defaultEndDate = new Date(this.lv_DefaultEndDate)

      if ( endDate > defaultEndDate ) {
        this.lv_EndDate = this.lv_DefaultEndDate
        endDate = new Date(this.lv_EndDate)
        this.lv_RenderingFlag = !this.lv_RenderingFlag
      }

      // 종료일자 자동선택
      if ( endDate < beginDate ) {
        this.fn_BottomAlert(this.$t('pm')['EPMC051'] + `<BR>(${this.lv_EndDate} → ${this.lv_BeginDate})`)
        this.lv_EndDate = this.lv_BeginDate
        this.lv_RenderingFlag = !this.lv_RenderingFlag
      }
    },
    /**
     * Function명 : fn_ResetDates
     * 설명       : 달력정보 초기화
     *              (시작일자: 당월 1일, 종료일자: 현재)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_ResetDates () {
      this.lv_BeginDate = this.lv_DefaultBeginDate
      this.lv_EndDate = this.lv_DefaultEndDate
    },
    /**
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_Search () {
      console.log('fn_Search call')
      // 동년, 동월여부 체크
      let beginDateYyyy = this.lv_BeginDate.split('-')[0]
      let beginDateMm = this.lv_BeginDate.split('-')[1]
      let endDateYyyy = this.lv_EndDate.split('-')[0]
      let endDateMm = this.lv_EndDate.split('-')[1]
      if ( beginDateYyyy !== endDateYyyy || beginDateMm !== endDateMm ) {
        this.fn_BottomAlert(this.$t('pm')['EPMC052'])
        return
      }

      // return data set
      let t_RtnData = {
        pBeginDate: this.lv_BeginDate,
        pEndDate: this.lv_EndDate
      }
      this.$emit('onPopupSearch', {'rtnData': t_RtnData})
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>
