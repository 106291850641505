/*
 * 업무구분   : 계약관리
 * 화면 명    : MSPPM912M
 * 화면 설명  : 심사진행현황
 */
<template>
  <ur-page-container title="심사진행현황" :show-title="false" type="subpage">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-checking-box">
            <div class="tool-top-box mb15">
              <span class="name">사고자<b>{{lv_BUInsmClamPrgStuRsListVO.acdtrNm || item.acdtrNm}}</b></span>
              <span class="ns-tool-tip">
                <mo-icon id="location_PM912M" icon="msp-tool-tip"></mo-icon>
                <mo-tooltip target-id="location_PM912M" class="tool-tip-box ns-tool-tip-scroll">
                  <span>
                    <b>서류접수일</b>
                    <br>
                    -보험금 청구서류를 접수한 날짜
                    <br><br>
                    <b>서류심사</b>
                    <br>
                    -처리 담당자가 서류를 확인하고, 지급, 본사심사, 현장 확인 여부 결정하는 단계
                    <br><br>
                    <b>현지실사</b>
                    <br>
                    -심사전문회사인 삼성생명서비스(주)에서 의료기관이나 관련기관 등 보험금과 관련한 사항 현장 확인하는 단계
                    <br><br>
                    <b>본사심사</b>
                    -서류심사 또는 현장확인 완료 후 최종지급여부를 결정하는 단계
                    <br><br>
                    <b>심사완료</b>
                    -보험금심사가 완료되어 지급/부지급 예정인 상태
                    <br><br>
                    <b>종료</b>
                    -사고보험금 청구에 대한 처리가 완료된 상태
                    <br><br>
                  </span>
                  <div class="bottom-box">
                    <span>
                      &#8251; 고객명은 보험금 청구대상 피보험자(보험대상자)의 성명입니다.
                    </span>
                    <span>
                      &#8251; 조회일 기준 3개월 이내 컨설턴트가 접수한 사고보험금 청구건에 한해 조회가 가능합니다.
                    </span>
                  </div>
                </mo-tooltip>
              </span>
            </div>
            <div class="ing-content">
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(0)">
                <span class="list-step">서류접수 {{lv_BUInsmClamPrgStuRsListVO.docuAcpnYmd}}</span>
              </div>
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(1)">
                <span class="list-step">서류심사 {{lv_BUInsmClamPrgStuRsListVO.docuJdgPrgStatCdNm}}</span>
              </div>
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(2)">
                <span class="list-step">현지실사 {{lv_BUInsmClamPrgStuRsListVO.lossAssmtPrgStatCdNm}}</span>
              </div>
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(3)">
                <span class="list-step">본사심사 {{lv_BUInsmClamPrgStuRsListVO.hdofJdgPrgStatCdNm}}</span>
              </div>
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(4)">
                <span class="list-step">심사완료 {{lv_BUInsmClamPrgStuRsListVO.pymAprvPrgStatCdNm}}</span>
              </div>
              <div class="ing-item" :class="fn_DcuAcpnIsActiClass(5)">
                <span class="list-step">종료 {{lv_BUInsmClamPrgStuRsListVO.endYmd}}</span>
              </div>
            </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
export default {
  name: 'MSPPM912M',
  screenId: 'MSPPM912M',
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  props: {
    item: {
      acdtrNm: String,
      aftAcpnYmd: String,
      befAcpnYmd: String,
      acdtrRrn: String
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    this.fn_GetBUInsmClamPrgStuRs()
  },
  mounted () {
    // 화면접속로그등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      lv_BUInsmClamPrgStuRsListVO: {},
      lv_AlertPop: ''
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /**
     * Function명 : fn_OnClose
     * 설명       : 팝업창 닫기
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_OnClose (e) {
      if (this.$popupManager) {
        this.$popupManager.close(this)
      }
    },
    /**
     * Function명 : fn_GetBUInsmClamPrgStuRs
     * 설명       : 심사진행현황 데이터 조회 및 화면에 반영
     * @return {none} - 반환없음
     */
    fn_GetBUInsmClamPrgStuRs () {
      let pParams = {
        acdtrNm: this.item.acdtrNm, // 사고자명
        aftAcpnYmd: this.item.aftAcpnYmd, // 전 접수일자
        befAcpnYmd: this.item.befAcpnYmd, // 후 접수일자
        acdtrRrn: this.item.acdtrRrn // 주민등록번호
      }
      let trnstId = 'txTSSBU29S1'

      this.post(this, pParams, trnstId, 'S')
        .then(response => {
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let list = response.body.bUInsmClamPrgStuRsListSVO
            if( list && list.length > 0 ){
              this.lv_BUInsmClamPrgStuRsListVO = list[0]
            }
          } else if (response.msgComm.msgCd === 'EEAC928') { // 데이터 미존재
          } else {
            this.fn_BottomAlert(response.msgComm.msgDesc)
          }
        })
        .catch(window.vue.error)
    },
    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /**
     * Function명 : fn_DcuAcpnIsActiClass
     * 설명       : 심사진행항목과 현재 상태에 따른 적절한 클래스 제공
     * @param {Number} param - 심사진행항목 ID
     */
    fn_DcuAcpnIsActiClass (param) {
      let retCls
      let keyList = [
        'docuAcpn',
        'docuJdgPrgStat',
        'lossAssmtPrgStat',
        'hdofJdgPrgStat',
        'pymAprvPrgStat',
        'endYmd'
      ]
      let keyIsActi = keyList[param] + 'IsActi'
      let keyIsSkip = keyList[param] + 'IsSkip'

      if (this.lv_BUInsmClamPrgStuRsListVO[keyIsActi] === true) {
        retCls = 'checked'
      } else if (this.lv_BUInsmClamPrgStuRsListVO[keyIsSkip]) {
        retCls = 'skip'
      } else {
        retCls = ''
      }
      return retCls
    }
  }
}
</script>
