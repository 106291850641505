/*
 * 업무구분: 사고보험금 조회
 * 화 면 명: MSPPM911M
 * 화면설명: 사고보험금 조회
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.03
 * 작 성 자: 김진원
 */
<!--
※ 사고보험금 조회의 [* 전체 콤포넌트 *] 구조
1. MSPPM911M 메인 (TSSBU026M)
  2. MSPPM911D 접수현황 탭 (TSSBU027D)
    2.1 MSPPM912M 심사진행현황 팝업
    2.2 MSPPM913M 지급내역 팝업
    2.3 MSPPM811M 구비서류 메인
      2.3.1 MSPPM812P 휴대폰&카카오 인증
      2.3.2 MSPPM814P 구비서류 추가

  3. MSPPM816D 접수중단건 탭 (TSSBU028D)
  4. MSPPM911P 접수현황 탭에서의 우측 상단 상세조회 팝업

-------------------------------------------------------------------------------------------------------------------------

※ 사고보험금 신청의 [* 전체 콤포넌트 *] 구조

1. MSPBU001M 메인
  2. MSPBU002D 신청자 고객정보조회 currentStep 1
  3. MSPBU005D 본인인증 대상 ==> {본인신청 : 본인}, {수익자상이 : 피보험자&수익자}, {자녀신청 : 계약자} currentStep 2
    3.1 MSPBU006D 휴대폰인증
    3.2 MSPBU007D 카카오페이 인증
    3.3 MSPBU008D 본인인증 동의 (요약형)
    3.4 MSPBU009D 본인인증 동의 (상세형)

  4. MSPBU032D 신청자 정보 확인 및 수금계약목록 currentStep 3
  5. MSPBU041D 청구서 작성 currentStep 4
  6. MSPBU051D 안내방법 선택 currentStep 5
    6.1 MSPBU055P 새로운계좌추가
  
  7. 전자서명 processing...
    7.1 전자서명 양식에 맞게 json 형식으로 데이터 셋 만들기.
  

그외 사고보험금 신청화면을 호출 하는 기타 화면들...
1. CustInfoBottomSheet.vue : 고객 바텀시트에서 고객명을 가지고 진입.
2. 사고보험금 조회 화면에서 "다음에하기" 건 이어서 진행 하는 경우.
3. 보유계약 목록에서 사고보험금신청 으로 진행 하는 경우.
-->
<template>
  <ur-page-container class="msp" title="사고보험금 조회" :show-title="true" type="subpage" :action-type="actionSlot" :topButton="true" > <!-- 2023.02.07 수정 //-->
    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
      <!-- 탭 -->
       <mo-tab-box :defaultIdx="$route.params.reset === 'BU'?2:1" @tab-change="fn_TabChangeHandler" ref="tabbox"  class="ns-move-tab-box w100">
        <mo-tab-content :idx="1" label="접수현황" >        
          <!-- 총건  -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title fexTy3">
                  <span class="sum">총 <strong class="crTy-blue3">{{tab1.totalCount}}</strong> 건</span>
                  <span class="ns-tool-tip mt7">
                    <mo-icon id="location0" icon="msp-tool-tip"></mo-icon>
                    <mo-tooltip target-id="location0" class="tool-tip-box right">
                      <ul class="terms-list-area">
                        <li>금융정보보호 신청을 하신 경우에는 접수현황조회에서 조회되지 않습니다. 유의부탁드립니다.</li>
                        <li>외화보험 달러($)지급건인 경우라도 청구금액은 원화로 환산하여 조회됩니다.</li>
                        <li>외화로 지급된 금액은 지급내역 선택하여 사고보험금 지급내역에서 확인 바랍니다.</li>
                      </ul>
                    </mo-tooltip>
                  </span>
                </div>
              </div>
            </mo-list-item>
            
          </ur-box-container>
          <!-- 총건  //-->
        </mo-tab-content>
        <mo-tab-content :idx="2" label="접수중단건" >
          <!-- 총건  -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
            <mo-list-item>
              <!-- <mo-checkbox v-model="tab2.isCheckAll" size="small" /> -->
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="sum">총 <strong class="crTy-blue3">{{tab2.totalCount}}</strong> 건</span>
                </div>
              </div>
              <!-- <div v-else class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="sum"><strong class="crTy-blue3">{{tab2.checkCount}}</strong> 명 선택</span>
                </div>
              </div> -->
              <span class="fs14rem crTy-bk7">※ 신청일 +5영업일 기준</span>
            </mo-list-item>
          </ur-box-container>
          <!-- 총건  //-->
        </mo-tab-content>
       </mo-tab-box>
      </ur-box-container>
    </template>
    <!-- 고정영역 //-->
    
    <MSPPM911D ref="MSPPM911D"      v-if="slctTabIdx === 1" :tabData="tab1" :slctTabIdx="slctTabIdx" @tab1Total="cnt => tab1.totalCount = cnt" @setDate="fn_SetDate"/>
    <MSPPM816D ref="MSPPM816D" v-else-if="slctTabIdx === 2" :tabData="tab2" :slctTabIdx="slctTabIdx" @tab2Total="cnt => tab2.totalCount = cnt"/>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPPM911D from '@/ui/pm/MSPPM911D' // 탭1: 접수현황
import MSPPM816D from '@/ui/pm/MSPPM816D' // 탭2: 접수중단건
import MSPPM911P from '@/ui/pm/MSPPM911P' // 상세조회
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import Screen from '~systems/mixin/screen'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                         -              *
   ***********************************************************************************/
  name: "MSPPM911M",
  screenId: "MSPPM911M",
  mixins: [Screen],
  components: {
    MSPPM911D,
    MSPPM816D,
    MSPPM911P,
  },
  props: {},

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    console.log('911 create')

    // Config 등록 기간에만 호출하는 프로세스
    this.fn_ConfigProcss()

    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick) // backKey event 등록
    
    const template = '<div slot="action" class="icon-wrapper icon-wrapper-type">' +
                        '<mo-button shape="border" class="ns-btn-round btn-title-style-1 mr0" @click="fn_OpenMSPBU001M">보험금 신청</mo-button>' +
                        '<mo-icon type="picto" @click="fn_OpenMSPPM911P" id="idSearchButton">search</mo-icon>' +
                      '</div>'

    let lv_Vm = this

    this.actionSlot = {
      actionComponent: {
        template: template,
        methods: {
          fn_OpenMSPPM911P() {
            console.log(typeof(moment().startOf('month').format('YYYY-MM-DD')))

            lv_Vm.popupPM911P = lv_Vm.$bottomModal.open(MSPPM911P, {
              properties: {
                pBeginDate: lv_Vm.tab1.beginDate,
                pEndDate: lv_Vm.tab1.endDate,
                pDefaultBeginDate: moment().startOf('month').format('YYYY-MM-DD'),
                pDefaultEndDate: moment().startOf('date').format('YYYY-MM-DD'),
                
              },
              listeners: {
                onPopupSearch: (pData) => {
                  //console.log('onPopupSearch call 911P!!!', pData)
                  lv_Vm.$bottomModal.close(lv_Vm.popupPM911P) // 팝업닫기
                  
                  lv_Vm.tab1.beginDate = pData.rtnData.pBeginDate
                  lv_Vm.tab1.endDate = pData.rtnData.pEndDate

                  console.log(pData.rtnData.pBeginDate, pData.rtnData.pEndDate)

                  lv_Vm.$refs.MSPPM911D.fn_GetService(true)
                },
                onPopupClose: () => {
                  lv_Vm.$bottomModal.close(lv_Vm.popupPM911P)
                }
              }
            })

          },
          fn_OpenMSPBU001M() {
            const lv_Vm = this

            let alertProps = {
                msg: '',
                closeBtn: false,
                preventTouchClose: false,
                closeBtn: true,
                preventTouchClose: true
              }


            if (lv_Vm.getStore('userInfo').getters.getUserInfo.isFc !== 'Y') {
              alertProps.msg = this.$t('pm')['EPMC021'] // "EPMC021": "FC만 이용 가능합니다."
            }

            if (!this.$bizUtil.isEmpty(alertProps.msg)) {
              this.$cmUtil.fn_OpenAlert(lv_Vm, alertProps)
              return
            }

            this.$router.push({name: 'MSPBU001M', params: {}})
          },
        }
      }
    }
  },
  mounted() {
    this.fn_TabChangeHandler(this.$route.params.reset === 'BU'?2:1)
    this.$bcUtil.getDataBySrnIdInLocalStorage(this)
  },

  //beforeRouteLeave(to, from, next) {
  //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)
  //next()
  //},

  beforeDestroy () {
    console.log('911 destory')
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)
  },
  
  destroyed() {
    this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
        slctTabIdx : 1,
        tab1: {
          totalCount: 0,
          beginDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().startOf('date').format('YYYY-MM-DD'),
        
        },
        tab2: {
          totalCount: 0,
        },
        stModelList: [], // S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명
    };
  },
  watch: {
    slctTabIdx(value) {
      console.log('slctTabIdx >', value)
      this.$bcUtil.setDataBySrnIdInLocalStorage(this)
    },
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_ClickHeaderLeftClick(){
      // 메뉴로 진입했으면 홈화면으로 이동한다.
      if (this.$route.query?._isMenuItem) {
        this.$router.push({name: 'MSPBC002M'})
        return
      }
      
      this.$router.go(-1)
    },

    /**
     * @name fn_TabChangeHandler
     * @description 탭 변경 이벤트
     * @param {Number} idx 변경된 탭 index
     */
    fn_TabChangeHandler(idx){      
      this.slctTabIdx = idx
      
      //접수현황이면 우측 상단 돋보기 버튼 표시
      if( this.slctTabIdx === 1 ){
        document.querySelector('#idSearchButton').style.display = 'block' //보이기
      } else {
        document.querySelector('#idSearchButton').style.display = 'none' //감추기
      }
    },

    /**
     * @name fn_SetDate
     * @description 상세조회에서 선택 한 조회 일자
     * @param {String} before 시작일자
     * @param {String} end 종료일자
     */
    fn_SetDate(before, end){
      this.tab1.beginDate = before
      this.tab1.end = end
    },

    /******************************************************************************
    * Function명 : fn_ConfigProcss
    * 설명       : 관리자페이지 - 업무 Config에 등록된 기간동안 프로세스 진행 (삭제 용이 목적)
    ******************************************************************************/
    fn_ConfigProcss () {
      const lv_Vm = this

      let pParams = {
        envId: 'PERIOD_SELECT'
      }
      
      let lv_today = moment(new Date()).format('YYYYMMDDHHmm')
      const trnstId = 'txTSSAD02S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null) {
            let configData = response.body.returnDtlList.filter(item => item.smoUnitl === 'PERIOD_1')
            if (_.isEmpty(configData)) return

            let startDate = JSON.parse(configData[0].envCntnt).startDate
            let endDate = JSON.parse(configData[0].envCntnt).endDate

            if (startDate <= lv_today && lv_today < endDate) {

              // 서비스중지 모델명 조회(현재 S24용)
              lv_Vm.fn_getS24MobpsStopModel()
            }

            console.log('fn_getS24MobpsStopModel.stModelList length:' + lv_Vm.stModelList.length )
          }
        })
        .catch(function (error) {
          console.log('■■■ fn_getS24MobpsStopModel.......')
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_getS24MobpsStopModel
    * 설명       : S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명 / S24_MOBPS_OPEN_STOP_MODEL
    * 서비스 중지 모델 : SM-S921N, SM-S926N, SM-S928N
    ******************************************************************************/
    fn_getS24MobpsStopModel() {
      /*
      .그룹 명/ID  : S24 모바일청약 바로열기 및 서면동의촬영 서비스중지 모델명 / S24_MOBPS_OPEN_STOP_MODEL
      .설명: 상세 이름/  값
      1) 서비스 중지 모델 : SM-S921N, SM-S926N, SM-S928N
      */
      const lv_Vm = this

      let pParams = {
        envId: 'S24_MOBPS_OPEN_STOP_MODEL'
      }

      const trnstId = 'txTSSAD02S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null) {
            lv_Vm.stModelList = response.body.returnDtlList
            console.log('fn_getS24MobpsStopModel.stModelList length:' + lv_Vm.stModelList.length )
          }
        })
        .catch(function (error) {
          console.log('■■■ fn_getS24MobpsStopModel.......')
          window.vue.error(error)
        })
    }
  }
}
</script>
<style scoped>
</style>